<template>
    <div class="home">
        <router-link :to="'/deliveries/' + batch.delivery_id" class="is-pulled-right" v-if="report_view != 1"><button class="button is-info is-small">Back to Order Details </button></router-link>
        <router-link :to="'/reports/batches-report'" class="is-pulled-right" v-if="report_view == 1"><button class="button is-info is-small">Back to Report </button></router-link>
        <br /><br />
        <div class="box">	
            <h4 class="title is-4">Batch Details</h4>
            
            <div class="notification is-info" v-if="loading === true">
                <p>Loading batch details ...</p>
            </div>
            
            <div class="columns ">
                <div class="column is-half">
                    <form  v-if="loading===false">
                    <div class="field">
                            <label class="label">Quantity of Stones</label>
                            <div class="control">
                                <input class="input" type="text" v-model="batch.qty" required  >
                                
                            </div>
                        </div>

                        

                        <div class="field">
                            <label class="label">Stone Make</label>
                            <div class="control">
                                <select class="input" v-model="batch.stone_make_id"  required>
                                    <option value="">Please select ...</option>
                                    <option :value="row.id" v-for="row in stone_makes" :key="row.id">{{ row.name }}</option>
                                
                                </select>
                                
                            </div>
                        </div>

                        <div class="field">
                            <label class="label">Block</label>
                            <div class="control">
                                <input class="input"   required v-model="batch.block" >                             
                            </div>     
                        </div>

                        <div class="field">
                            <label class="label">Length (mm)</label>
                            <div class="control">
                                <input class="input"   required v-model="batch.length" >                       
                            </div>     
                        </div>
                        <div class="field">
                            <label class="label">Height (mm)</label>
                            <div class="control">
                                <input class="input"   required v-model="batch.height" >                     
                            </div>     
                        </div>
                        <div class="field">
                            <label class="label">Thickness (mm)</label>
                            <div class="control">
                                <input class="input"   required v-model="batch.thickness" >                       
                            </div>     
                        </div>
                    

                        
                    </form>
                </div>

                <div class="column is-half">
                    <div class="field" v-if="loading == false && batch.image != ''">
                        <label class="label">Batch Photo</label>
                        <div class="control">
                            <img :src="'data:image/jpeg;base64,' + batch.image_base64" style="width:100%" v-if="batch.image_base64 != '' && 1==2"> 
                            <img :src="API_NAME + '/batches/' + batch.id + '/image'" style="width:100%" v-if="batch.image != '' " loading="lazy">                          
                        </div>
                    </div>

                    <div v-if="batch.image == ''">
                        <form @submit.prevent="updateBatchPhoto" v-if="loading===false">
                            <div class="field" >
                                <label class="label">Upload New Photo</label>
                                <div class="control">
                                    <input type="file" class="input" @change="handleImage()" accept="image/*"/>
                                    <input type="hidden" class="input" v-model="image_base64" />                   
                                </div>
                            </div>

                            <div class="field is-grouped">
                                <div class="control">
                                    <button class="button is-success" :disabled="working===true">Upload Photo</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
            
          </div>

          <div class="box">
            <h4 class="title is-4">Stones Assigned To This Batch</h4>

            <div class="notification is-info" v-if="loading === true">
                <p>Loading stones ...</p>
            </div>

            <table class="table is-fullwidth is-hoverable" v-if="loading === false">
                <thead>
                    <tr>
                        <th>Barcode</th>
                        <th>In Use / On Hold</th>
                        <th>Job</th>
                        <th width="50">&nbsp;</th>
                    </tr>

                </thead>
                <tbody>
                    <tr v-for="row in batch.stones" :key="row.id">
                        <td><span v-if="row.barcode != ''"><router-link :to="'/stones/' + row.id">{{ row.barcode}}</router-link></span>
                            <span v-if="row.barcode=='' || row.barcode==null"><em>Barcode not yet assigned</em></span>
                        </td>
                        <td>
                            <div v-if="row.in_use == true">
                                <span class="tag is-success" >In Use</span> 
                                <span v-if="row.in_use_note != ''"> {{ row.in_use_note  }}</span>
                            </div>
                            <div v-if="row.on_hold == true">
                                <span class="tag is-danger" >On Hold</span> 
                                <span v-if="row.on_hold_notes != ''"> {{ row.on_hold_notes  }}</span>
                            </div>
                        </td>
                        <td>{{ row.job_name }}</td>
                        <td>
                            <button class="button is-small is-danger" v-if="row.on_hold==false" @click="showHoldModal(row)" :disabled="working==true">Put On Hold</button>
                            
                        </td>
                    </tr>
                </tbody>
            </table>

          </div>

          <div class="modal" :class="{'is-active': showOnHoldModal == true}">
            <div class="modal-background"></div>
                <div class="modal-content">
                    <!-- Any other Bulma elements you want -->
                    <div class="card">
                    <div class="card-content">
                        <div class="content">
                        <form @submit.prevent="setStoneOnHold()">
                            <div class="field">
                                <label class="label">Add An "On Hold" Note ?</label>
                                <div class="control">
                                    <textarea class="textarea" maxlength="500" type="text" placeholder="" rows="3" v-model="on_hold_notes"></textarea>
                                </div>
                            </div>

                            <div class="field is-grouped">
                                <div class="control">
                                    <button class="button is-success" >Save</button>
                                </div>
                               
                            </div>
                            </form>
                        </div>
                    </div>
                    </div>
                </div>
            <button class="modal-close is-large" aria-label="close" @click="hideModal()"></button>
        </div>
          
      </div>
  </template>

<script>
    //import AwardsMixin from '@/mixins/AwardsMixin.js';
    import StoneService from '@/services/StoneService.js';
    import UtilityService from '@/services/UtilityService.js';
    
    export default {
      name: 'ViewBatch',
        mixins: [],
        data: function () {
            return {
                   loading : true,
                  search: "",
                    API_NAME: process.env.VUE_APP_API_NAME,
                    accessToken: '',
                    batch: {id: 0},
                    working:false,
                    stone_types: [],
                    stone_makes: [],
                    image_base64: '',
                    report_view : this.$route.query.report_view,
                    showOnHoldModal: false,
                    on_hold_notes: '',
                    onHoldStone: []
                    
            }
        },
        created() {
          this.getAccessToken()
            .then(() => { 
                this.getBatch();
                this.getStoneTypes();
               this.getStoneMakes();
            });
          
        },
        methods: {
            handleImage() {
                const selectedImage = event.target.files[0];
                this.createBase64Image(selectedImage);
            },
            createBase64Image(fileObject) {
                const reader = new FileReader();
                reader.readAsDataURL(event.target.files[0])
                reader.onload = () => {
                    this.image_base64 = reader.result;
                };
                reader.readAsBinaryString(fileObject)
            },  
            async getBatch() {
                this.loading = true
                    StoneService.getBatch(this.accessToken, this.$route.params.id)
                    .then(
                        (batch => {
                             this.$set(this, "batch", batch);
                            
                            this.loading=false
                            this.working = false
                        }).bind(this)
                    );
                
            },
            async getStoneTypes() {
                UtilityService.getSimpleApiData(this.accessToken, "stone_types").then(
                    response => this.stone_types = response 
                );
                
            },
            async getStoneMakes() {
                UtilityService.getSimpleApiData(this.accessToken, "stone_makes").then(
                    response => this.stone_makes = response 
                );
                
            },
            async updateBatchPhoto() {
                this.working = true
                var formdata = { 
                    _method: 'patch',
                    image_base64 : this.image_base64,
                    
                }; 

                StoneService.updateBatchPhoto(this.accessToken, formdata, this.batch.id)
                .then((response) => {
                    this.getBatch();
                    console.log(response)
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                        }
                        alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        this.working = false
                });
            },
            async setStoneOnHold() {
                this.working = true

                var formdata = { 
                    _method: 'patch',
                    on_hold : 1,
                    on_hold_notes: this.on_hold_notes,
                    on_hold_date : 'now()'
                }; 

                StoneService.setStoneInUse(this.accessToken, this.onHoldStone.id, formdata)
                .then( () => this.getBatch() )
                .then( () => this.hideModal() )
            },
            hideModal() {
                this.showOnHoldModal = false
            },
            
            showHoldModal(row) {
                this.onHoldStone = row
                this.showOnHoldModal = true
            },
           
        }
    }
    </script>