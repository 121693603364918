<template>
    <div class="home">
        <router-link to="/stones" class="is-pulled-right"><button class="button is-info is-small">Back to Stones </button></router-link><br /><br />
        <div class="box">	
              
            
            <form @submit.prevent="saveStone">
                    <div class="field" v-if="multi_add==false">
                          <label class="label">Barcode ( <a @click="multi_add=true">Switch to Multi Add</a> )</label>
                          <div class="control">
                              <input class="input" type="text" v-model="barcode"  >
                             
                          </div>
                      </div>

                      <div class="field" v-if="multi_add==true">
                        <label class="label">Barcodes ( <a @click="multi_add=false">Switch to Single Add</a> )</label>
                        <div class="columns">
                            <div class="column">
                                <div class="control">
                                    <input class="input" type="text" v-model="barcode" placeholder="Starting Barcode" required>
                                    
                                </div>
                            </div>
                            <div class="column">
                                <div class="control">
                                <input class="input" type="text" v-model="barcode2" placeholder="Ending Barcode" required>
                                
                            </div>
                            </div>
                            
                            </div>  
                       
                      </div>
      
                
                        <div class="field">
                          <label class="label">Stone Type</label>
                          <div class="control">
                              <select class="input" v-model="stone_type_id"  >
                                <option value="">Please select ...</option>
                                <option :value="row.id" v-for="row in stone_types" :key="row.id">{{ row.name }}</option>
                               
                            </select>
                             
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Stone Make</label>
                          <div class="control">
                              <select class="input" v-model="stone_make_id"  required>
                                <option value="">Please select ...</option>
                                <option :value="row.id" v-for="row in stone_makes" :key="row.id">{{ row.name }}</option>
                               
                            </select>
                             
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Block</label>
                          <div class="columns">
                                <div class="column is-two-thirds"> 
                                    <div class="control">
                                        <select class="input"   required>
                                            <option value="">Please select ...</option>
                                            <option value="">122</option>
                                            <option value="">542678</option>
                                            <option value="">DAVID1234</option>
                                        </select>
                                    
                                    </div>
                                </div>
                                <div class="column is-one-third"> 
                                    <div class="control">
                                        <button class="button is-link">Add A New Block for this Make</button>
                                    
                                    </div>
                                </div>
                            </div>
                      </div>
                    
                      <div class="columns">
                            <div class="column">
                                <div class="field">
                                    <label class="label">Length (mm)</label>
                                    <div class="control">
                                        <input type="number" class="input">
                                    </div>
                                </div>
                            </div>
                            <div class="column">
                                <div class="field">
                                    <label class="label">Height (mm)</label>
                                    <div class="control">
                                        <input type="number" class="input">
                                    </div>
                                </div>
                            </div>
                            <div class="column">
                                <div class="field">
                                    <label class="label">Thickness (mm)</label>
                                    <div class="control">
                                        <input type="number" class="input" value="20">
                                    </div>
                                </div>
                            </div>
                    </div>

                      <div class="field">
                          <label class="label">Associated Job</label>
                          <div class="control">
                              <select class="input" v-model="job_id" >
                                <option value="">Please select ...</option>
                                <option :value="row.id" v-for="row in jobs" :key="row.id">{{ row.name }}</option>
                                
                            </select>
                             
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Linked to Delivery</label>
                          <div class="control">
                              <select class="input" v-model="delivery_id" >
                                <option value="">Please select ...</option>
                                <option :value="row.id" v-for="row in deliveries" :key="row.id">{{ row.supplier_name }} - {{ row.description }}</option>
                                
                            </select>
                             
                          </div>
                      </div>

                      <div class="field is-grouped">
                          <div class="control">
                              <button class="button is-success" :disabled="working===true">Save Stone</button>
                          </div>
                      </div>
                  </form>
            
          </div>
          
      </div>
  </template>

<script>
    //import AwardsMixin from '@/mixins/AwardsMixin.js';
    import StoneService from '@/services/StoneService.js';
    import UtilityService from '@/services/UtilityService.js';
    
    export default {
      name: 'AddStone',
        mixins: [],
        data: function () {
            return {
                   loading : false,
                  search: "",
                    API_NAME: process.env.VUE_APP_API_NAME,
                    accessToken: '',
                    jobs: [],
                    stone_types: [],
                    stone_makes: [],
                    stone_type_id: '',
                    stone_make_id: '',
                    job_id: '',
                    barcode: '',
                    barcode2:'',
                    working:false,
                    deliveries: [],
                    delivery_id: '',
                    multi_add:false
                    
                   
            }
        },
        created() {
          this.getAccessToken()
            .then(() => { 
               this.getJobs();
               this.getStoneTypes();
               this.getStoneMakes();
               this.getDeliveries();
            });
          
        },
        methods: {
            async getJobs() {
                UtilityService.getSimpleApiData(this.accessToken, "jobs").then(
                    response => this.jobs = response 
                );
                
            },
            async getStoneTypes() {
                UtilityService.getSimpleApiData(this.accessToken, "stone_types").then(
                    response => this.stone_types = response 
                );
                
            },
            async getStoneMakes() {
                UtilityService.getSimpleApiData(this.accessToken, "stone_makes").then(
                    response => this.stone_makes = response 
                );
                
            },
            async getDeliveries() {
                UtilityService.getSimpleApiData(this.accessToken, "deliveries?received=0").then(
                    response => this.deliveries = response 
                );
                
            },

            async saveStone() {
                this.working = true
                var formdata = { 
                    multi_add: this.multi_add,
                    barcode : this.barcode,
                    barcode2 : this.barcode2,
                    stone_make_id : this.stone_make_id,
                    job_id : this.job_id,
                    delivery_id : this.delivery_id
                    
                }; 

                // Validate the form
                if(this.multi_add == true && (this.barcode == '' || this.barcode2 == ''))
                {
                    alert("Please make sure both barcodes are scanned or typed")
                    return false
                }
                else{
                    StoneService.saveStone(this.accessToken, formdata)
                    .then((response) => {
                        this.$router.push("/stones");
                        console.log(response)
                        
                        }).catch((error) => {
                            if(error.response.status === 422)
                            {
                                console.log("Error:" + JSON.stringify(error.response.data));
                                alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                            }
                            if(error.response.status === 406)
                            {
                                console.log("Error:" + JSON.stringify(error.response.data));
                                alert("The barcodes passed in were not valid. Please double check and try again.")
                            }
                           
                            this.working = false
                    });
                }

                
            },
           
        }
    }
    </script>