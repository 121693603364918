<template>
    <div>
        <router-link to="/stone_makes/add"><button class="button is-success">Add New Stone Make</button></router-link><br /><br />

        <div class="notification is-info" v-if="loading === true">
            <p>Loading stone makes ...</p>
        </div>

        <div class="notification is-warning" v-if="loading === false && stone_makes.length == 0">
            <p>There are no stone makes to display</p>
        </div>
        
        <table class="table is-fullwidth is-striped" v-if="loading === false && stone_makes.length > 0">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Description</th>
                    <th width="50" v-if="1==2"></th>
                    <th width="50"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in stone_makes" :key="row.id">
                    <td>{{ row.name }}</td>
                    <td>{{ row.stone_type_name }}</td>
                    <td>{{ row.description }}</td>
                    <td v-if="1==2"><button class="button is-small is-danger">Archive</button></td>
                    <td><button class="button is-small is-link" @click="notWorking()">View</button></td>
                </tr>
                
            </tbody>
        </table>
    </div>

</template>

<script>
    import StoneService from '@/services/StoneService.js';
    //import UtilityService from '@/services/UtilityService.js';
    
    export default {
      name: 'AddOrganisation',
        mixins: [],
        data: function () {
            return {
                   loading : false,
                  search: "",
                    API_NAME: process.env.VUE_APP_API_NAME,
                    accessToken: '',
                    stone_make_id : '',
                    job_id: '',
                    stone_makes: []
                   
            }
        },
        methods: {
            
            getStoneMakes() {
                this.loading=true;
                StoneService.getStoneMakes(this.accessToken)
                .then(
                    (stone_makes => {
                    
                    this.$set(this, "stone_makes", stone_makes);
                    this.loading=false;
                    }).bind(this)
                );
            },
            notWorking() {
                alert('Not yet working')
            }
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getStoneMakes()
            });
        
        },
    }
</script>