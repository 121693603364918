<template>
    <div class="home">
          <div class="box">	
              <form @submit.prevent="saveColour">
                      <div class="field">
                          <label class="label">Colour Name </label>
                          <div class="control">
                              <input class="input" type="text" v-model="name" autofocus required>
                             
                          </div>
                      </div>

  
                     
                      <div class="field is-grouped">
                          <div class="control">
                              <button class="button is-success" :disabled="loading===true">Save Colour</button>
                          </div>
                      </div>
                  </form>
            
          </div>
          
      </div>
  </template>

<script>
    //import AwardsMixin from '@/mixins/AwardsMixin.js';
    import StoneService from '@/services/StoneService.js';
    //import UtilityService from '@/services/UtilityService.js';
    
    export default {
      name: 'AddOrganisation',
        mixins: [],
        data: function () {
            return {
                   loading : false,
                  search: "",
                    API_NAME: process.env.VUE_APP_API_NAME,
                    accessToken: '',
                    name: '',
                    
                   
            }
        },
        created() {
          this.getAccessToken()
            .then(() => { 
              
            });
          
        },
        methods: {
            
            async saveColour() {
			
                var formdata = { 
                    name : this.name,
                    
                }; 

                StoneService.saveColour(this.accessToken, formdata)
                .then((response) => {
                    this.$router.push("/colours");
                    console.log(response)
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                        }
                        alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                });
            },
           
        }
    }
    </script>