<template>
  <div class="home mt-5">
    <div class="columns buttons are-large">
        <div class="column is-half ">
          <router-link to="/stone_types"><button class="button is-info is-fullwidth">Stone Types</button></router-link>
        </div>
        <div class="column is-half">
          <router-link to="/stone_makes"><button class="button is-info is-fullwidth">Stone Makes</button></router-link>
        </div>
        <div class="column is-half">
          <router-link to="/suppliers"><button class="button is-info is-fullwidth">Suppliers</button></router-link>
        </div>
      </div>
    
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
    
  }
}
</script>
