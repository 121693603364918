<template>
    <div class="home">
        <router-link to="/jobs" class="is-pulled-right"><button class="button is-info is-small">Back to Jobs </button></router-link><br /><br />
        <div class="box">	
            <h4 class="title is-4">Job Details</h4>
            
            <div class="notification is-info" v-if="loading === true">
                <p>Loading job details ...</p>
            </div>
            
            <form @submit.prevent="updateJob" v-if="loading===false">
                <div class="field">
                          <label class="label">Job Name </label>
                          <div class="control">
                              <input class="input" type="text" v-model="job.name" required autofocus >
                             
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Description</label>
                          <div class="control">
                              <input class="input" type="text" v-model="job.description"  >
                             
                          </div>
                      </div>
      
                      <div class="field">
                          <label class="label">Start Date</label>
                          <div class="control">
                              <input class="input" type="date" v-model="job.start_date"  >
                             
                          </div>
                      </div>
                

                      <div class="field is-grouped">
                          <div class="control">
                              <button class="button is-success" :disabled="working===true">Update Job</button>
                          </div>
                      </div>
                  </form>
            
          </div>

          <div class="box">
            <h4 class="title is-4">Stones Assigned To Job</h4>

            <div class="notification is-info" v-if="loading === true">
                <p>Loading stones ...</p>
            </div>

            <table class="table is-fullwidth" v-if="loading === false">
                <thead>
                    <tr>
                        <td>Make</td>
                        <td>Type</td>
                        <td>Barcode</td>
                    </tr>

                </thead>
                <tbody>
                    <tr v-for="row in job.stones" :key="row.id">
                        <td>{{ row.stone_make_name}}</td>
                        <td>{{ row.stone_type_name}}</td>
                        <td>{{ row.barcode}}</td>
                    </tr>
                </tbody>
            </table>

          </div>
          
      </div>
  </template>

<script>
    //import AwardsMixin from '@/mixins/AwardsMixin.js';
    import StoneService from '@/services/StoneService.js';
    //import UtilityService from '@/services/UtilityService.js';
    
    export default {
      name: 'ViewStone',
        mixins: [],
        data: function () {
            return {
                   loading : true,
                  search: "",
                    API_NAME: process.env.VUE_APP_API_NAME,
                    accessToken: '',
                    job: {id: 0},
                    working:false
                    
            }
        },
        created() {
          this.getAccessToken()
            .then(() => { 
                this.getJob();
              
            });
          
        },
        methods: {
           
            async getJob() {
                
                    StoneService.getJob(this.accessToken, this.$route.params.id)
                    .then(
                        (job => {
                             this.$set(this, "job", job);
                            
                        this.loading=false
                        }).bind(this)
                    );
                
            },

            async updateJob() {
                this.working = true
                var formdata = { 
                    _method: 'patch',
                    name : this.job.name,
                    description : this.job.description,
                    start_date : this.job.start_date,
                    
                }; 

                StoneService.updateJob(this.accessToken, formdata, this.job.id)
                .then((response) => {
                    this.$router.push("/jobs");
                    console.log(response)
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                        }
                        alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        this.working = false
                });
            },
           
        }
    }
    </script>