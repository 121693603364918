<template>
    <div class="home">
        <router-link to="/stone_types" class="is-pulled-right"><button class="button is-info is-small">Back to Stone Types </button></router-link><br /><br />
        
        <div class="box">	
              <form @submit.prevent="saveStoneType">
                      <div class="field">
                          <label class="label">Stone Type Name </label>
                          <div class="control">
                              <input class="input" type="text" v-model="name" autofocus required>
                             
                          </div>
                      </div>

                      

                      <div class="field">
                          <label class="label">Description </label>
                          <div class="control">
                              <textarea class="textarea" v-model="description"  ></textarea>

                             
                          </div>
                      </div>
  
                     
                      <div class="field is-grouped">
                          <div class="control">
                              <button class="button is-success" :disabled="working===true">Save Stone Type</button>
                          </div>
                      </div>
                  </form>
            
          </div>
          
      </div>
  </template>

<script>
    //import AwardsMixin from '@/mixins/AwardsMixin.js';
    import StoneService from '@/services/StoneService.js';
    import UtilityService from '@/services/UtilityService.js';
    
    export default {
      name: 'AddStoneType',
        mixins: [],
        data: function () {
            return {
                   working : false,
                  search: "",
                    API_NAME: process.env.VUE_APP_API_NAME,
                    accessToken: '',
                    colours: [],
                    name: '',
                    colour_id: '',
                    description: ''
                   
            }
        },
        created() {
          this.getAccessToken()
            .then(() => { 
               this.getColours();
            });
          
        },
        methods: {
            async getColours() {
                UtilityService.getSimpleApiData(this.accessToken, "colours").then(
                    response => this.colours = response 
                );
                
            },

            async saveStoneType() {
                this.working = true;
                var formdata = { 
                    name : this.name,
                    description : this.description
                }; 

                StoneService.saveStoneType(this.accessToken, formdata)
                .then((response) => {
                    this.$router.push("/stone_types");
                    console.log(response)
                    this.working = false
                    
                    }).catch((error) => {
                        if(error.response.status === 400)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data.error));
                            alert("Something went wrong. Make sure you have filled in all the fields. \n\nError : " + error.response.data.error)
                        }
                        else
                        {
                            alert("Something went wrong. Please try again or contact David.")
                        }
                        
                        this.working = false
                });
            },
           
        }
    }
    </script>