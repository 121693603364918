<template>
    <div class="home">
        <router-link to="/jobs" class="is-pulled-right" style="display:none"><button class="button is-info is-small">Back to Jobs </button></router-link><br /><br />
        <div class="box">	
            <h4 class="title is-4">Batch Details</h4>
            
            <div class="notification is-info" v-if="loading === true">
                <p>Loading batch details ...</p>
            </div>
            
            <form @submit.prevent="saveBatch" v-if="loading===false">
                <div class="field">
                          <label class="label">Quantity of Stones</label>
                          <div class="control">
                              <input class="input" type="text" v-model="qty" required autofocus >
                             
                          </div>
                      </div>
                    
                      <div class="field" v-if="qty < 2">
                          <label class="label">Barcode </label>
                          <div class="control">
                              <input class="input" type="text" v-model="barcode" @change="verifyBarcodes()" >
                             
                          </div>
                      </div>

                      <div class="field" v-if="qty > 1">
                        <label class="label">Barcodes </label>
                        <div class="columns">
                            <div class="column">
                                <div class="control">
                                    <input class="input" type="text" v-model="barcode" @change="verifyBarcodes()" placeholder="Starting Barcode" >
                                    
                                </div>
                            </div>
                            <div class="column">
                                <div class="control">
                                <input class="input" type="text" v-model="barcode2" @change="verifyBarcodes()" placeholder="Ending Barcode" >
                                
                            </div>
                            </div>
                            
                            </div>  
                       
                      </div>

                      <div class="field">
                          <label class="label">Stone Type</label>
                          <div class="control">
                              <select class="input" v-model="stone_type_id"  >
                                <option value="">Please select ...</option>
                                <option :value="row.id" v-for="row in stone_types" :key="row.id">{{ row.name }}</option>
                               
                            </select>
                             
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Stone Make</label>
                          <div class="control">
                              <select class="input" v-model="stone_make_id"  required>
                                <option value="">Please select ...</option>
                                <option :value="row.id" v-for="row in filtered_stone_makes" :key="row.id">{{ row.name }}</option>
                               
                            </select>
                             
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Block</label>
                          <div class="columns">
                                <div class="column "> 
                                    <div class="control">
                                        <input type="text" class="input" v-model="block" />
                                    
                                    </div>
                                </div>
                                
                            </div>
                      </div>

                      <div class="columns">
                            <div class="column">
                                <div class="field">
                                    <label class="label">Length (mm)</label>
                                    <div class="control">
                                        <input type="number" class="input" v-model="length">
                                    </div>
                                </div>
                            </div>
                            <div class="column">
                                <div class="field">
                                    <label class="label">Height (mm)</label>
                                    <div class="control">
                                        <input type="number" class="input" v-model="height">
                                    </div>
                                </div>
                            </div>
                            <div class="column">
                                <div class="field">
                                    <label class="label">Thickness (mm)</label>
                                    <div class="control">
                                        <input type="number" class="input" v-model="thickness">
                                    </div>
                                </div>
                            </div>
                    </div>

                    <div class="field">
                          <label class="label">Image Upload</label>
                          <div class="columns">
                                <div class="column "> 
                                    <div class="control">
                                        <input type="file" class="input" @change="handleImage()" accept="image/*"/>
                                        <input type="hidden" class="input" v-model="image_base64" />
                                    </div>
                                </div>
                                
                            </div>
                      </div>
                

                      <div class="field is-grouped">
                          <div class="control">
                              <button class="button is-success" :disabled="working===true">Save Batch</button>
                          </div>
                      </div>
                  </form>
            
          </div>

         
          
      </div>
  </template>

<script>
    //import AwardsMixin from '@/mixins/AwardsMixin.js';
    import StoneService from '@/services/StoneService.js';
    import UtilityService from '@/services/UtilityService.js';
    
    export default {
      name: 'AddBatch',
        mixins: [],
        data: function () {
            return {
                id: this.$route.params.id,
                   loading : false,
                  search: "",
                    API_NAME: process.env.VUE_APP_API_NAME,
                    accessToken: '',
                    job: {id: 0},
                    working:false,
                    stone_types: [],
                    stone_makes: [],
                    qty: 1,
                    barcode: '',
                    barcode2: '',
                    stone_make_id: '',
                    stone_type_id: '',
                    block: '',
                    length: '',
                    height: '',
                    thickness: 20,
                    image_file: '',
                    image_base64: ''
                    
            }
        },
        created() {
          this.getAccessToken()
            .then(() => { 
               
                this.getStoneTypes();
               this.getStoneMakes();
            });
          
        },
        methods: {
            handleImage() {
                const selectedImage = event.target.files[0];
                this.createBase64Image(selectedImage);
            },
            createBase64Image(fileObject) {
                const reader = new FileReader();
                reader.readAsDataURL(event.target.files[0])
                reader.onload = () => {
                    
                    this.image_base64 = reader.result;
                };
                reader.readAsBinaryString(fileObject)
            },  
            async getStoneTypes() {
                UtilityService.getSimpleApiData(this.accessToken, "stone_types").then(
                    response => this.stone_types = response 
                );
                
            },
            async getStoneMakes() {
                UtilityService.getSimpleApiData(this.accessToken, "stone_makes").then(
                    response => this.stone_makes = response 
                );
                
            },
            async saveBatch() {
                
                
                this.working = true
                var formdata = { 
                    delivery_id: this.id,
                    qty : this.qty,
                    stone_make_id : this.stone_make_id,
                    barcode : this.barcode,
                    barcode2 : this.barcode2,
                    block : this.block,
                    length : this.length,
                    height : this.height,
                    thickness : this.thickness,
                    image_base64 : this.image_base64,
                }; 

                StoneService.saveBatch(this.accessToken, formdata, this.id)
                .then((response) => {
                    this.$router.push("/deliveries/" + this.id);
                    console.log(response)
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                            alert("Something went wrong.\n\n" + JSON.stringify(error.response.data))
                        }
                        else
                        {
                            alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        }
                        
                        this.working = false
                });
            },
            verifyBarcodes() {
                if(this.barcode != "" && this.barcode != null)
                {
                    this.barcode = this.fixBarcodeInput(this.barcode)
                }
                if(this.barcode2 != "" && this.barcode2 != null)
                {
                    this.barcode2 = this.fixBarcodeInput(this.barcode2)
                }
            },
            fixBarcodeInput(inputValue)
            {
                inputValue = inputValue.trim()
                inputValue = inputValue.replace(/\D/g,'');

                if(inputValue.length < 7)
                {
                    inputValue = inputValue.padStart(7, '0')
                }

                if(inputValue.startsWith("TM") == false)
                {
                    inputValue = "TM" + inputValue
                }
                
                inputValue = inputValue.toUpperCase()
                return inputValue
            }
           
        },
        computed: {
            filtered_stone_makes: function () {
                let filtered_stone_makes = []
                
                if(this.stone_type_id != "")
                {
                    filtered_stone_makes = this.stone_makes.filter(row => row.stone_type_id == this.stone_type_id);
                }
                else{
                    filtered_stone_makes = this.stone_makes;
                }

                return filtered_stone_makes
            }
        }
    }
    </script>