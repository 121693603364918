<template>
    <div class="home">
          <div class="box">	
              <form @submit.prevent="saveJob">
                      <div class="field">
                          <label class="label">Job Name </label>
                          <div class="control">
                              <input class="input" type="text" v-model="name" autofocus required>
                             
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Description </label>
                          <div class="control">
                              <input class="input" type="text" v-model="description" >
                             
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Start Date </label>
                          <div class="control">
                              <input class="input" type="date" v-model="start_date" >
                             
                          </div>
                      </div>

  
                     
                      <div class="field is-grouped">
                          <div class="control">
                              <button class="button is-success" :disabled="working===true">Save Job</button>
                          </div>
                      </div>
                  </form>
            
          </div>
          
      </div>
  </template>

<script>
    //import AwardsMixin from '@/mixins/AwardsMixin.js';
    import StoneService from '@/services/StoneService.js';
    //import UtilityService from '@/services/UtilityService.js';
    
    export default {
      name: 'AddOrganisation',
        mixins: [],
        data: function () {
            return {
                   loading : false,
                  search: "",
                    API_NAME: process.env.VUE_APP_API_NAME,
                    accessToken: '',
                    name: '',
                    description: '',
                    start_date: this.getStartDate(),
                    working: false
                    
                   
            }
        },
        created() {
          this.getAccessToken()
            .then(() => { 
              
            });
          
        },
        methods: {
            
            async saveJob() {
                this.working = true
                var formdata = { 
                    name : this.name,
                    description : this.description,
                    start_date : this.start_date,
                    
                }; 

                StoneService.saveJob(this.accessToken, formdata)
                .then((response) => {
                    this.$router.push("/jobs");
                    console.log(response)
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                        }
                        alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        this.working = false
                });
            },
            getStartDate() {
            
                var d = new Date();
                
                d.setDate(d.getDate());
                return d.toISOString().slice(0,10);
                
            },
           
        }
    }
    </script>