<template>
    <div>
        <router-link to="/stone_types/add"><button class="button is-success is-pulled-left">Add New Stone Type</button></router-link>
        <router-link to="/admin"><button class="button is-info is-pulled-right">Back to Admin Area</button></router-link>
        <br /><br />

        <div class="notification is-info" v-if="loading === true">
            <p>Loading stone types ...</p>
        </div>

        <div class="notification is-warning" v-if="loading === false && active_stone_types.length == 0">
            <p>There are no stone types to display</p>
        </div>
        
        <table class="table is-fullwidth is-striped" v-if="loading === false && active_stone_types.length > 0">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th width="50" ></th>
                    <th width="50"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in active_stone_types" :key="row.id">
                    <td>{{ row.name }}</td>
                    <td>{{ row.description }}</td>
                    <td ><button class="button is-small is-danger" @click="archiveStoneType(row)" :disabled="working==true">Archive</button></td>
                    <td><router-link :to="'/stone_types/' + row.id"><button class="button is-small is-link" :disabled="working==true">View</button></router-link></td>
                </tr>
                
            </tbody>
        </table>
    </div>

</template>

<script>
    import StoneService from '@/services/StoneService.js';
    //import UtilityService from '@/services/UtilityService.js';
    
    export default {
      name: 'AddOrganisation',
        mixins: [],
        data: function () {
            return {
                   loading : true,
                   working: false,
                  search: "",
                    API_NAME: process.env.VUE_APP_API_NAME,
                    accessToken: '',
                    stone_type_id : '',
                    job_id: '',
                    stone_types: []
                   
            }
        },
        methods: {
            
            getStoneTypes() {
                this.loading=true;
                StoneService.getStoneTypes(this.accessToken)
                .then(
                    (stone_types => {
                    
                    this.$set(this, "stone_types", stone_types);
                    this.loading=false;
                    }).bind(this)
                );
            },
            async archiveStoneType(stoneType) {
                this.working = true;
                
                StoneService.archiveStoneType(this.accessToken, stoneType.id)
                .then( () => {
                    this.working = false;
                    stoneType.active = 0
                    }
                );
            },
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getStoneTypes()
            });
        
        },
        computed : {
            active_stone_types: function () {
                let activeStoneTypes =  this.stone_types.filter(function(u) {
                    if(  u.active == 1)
                    {
                    return 1
                    }
                })

               
                return activeStoneTypes
            
            },
           
        },
    }
</script>