<template>
    <div class="home">
          <div class="box">	
              <form @submit.prevent="saveStoneMake">
                      <div class="field">
                          <label class="label">Stone Make Name </label>
                          <div class="control">
                              <input class="input" type="text" v-model="name" autofocus required>
                             
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Stone Type </label>
                          <div class="control">
                              <select class="input" required v-model="stone_type_id">
                                <option value="">Select Stone Type</option>
                                <option :value="row.id" v-for="row in stone_types" :key="row.id">{{ row.name }}</option>
                              </select>
                             
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Description </label>
                          <div class="control">
                              <textarea class="textarea" v-model="description"  ></textarea>

                             
                          </div>
                      </div>
  
                     
                      <div class="field is-grouped">
                          <div class="control">
                              <button class="button is-success" :disabled="loading===true">Save Stone Make</button>
                          </div>
                      </div>
                  </form>
            
          </div>
          
      </div>
  </template>

<script>
    //import AwardsMixin from '@/mixins/AwardsMixin.js';
    import StoneService from '@/services/StoneService.js';
    import UtilityService from '@/services/UtilityService.js';
    
    export default {
      name: 'AddStoneType',
        mixins: [],
        data: function () {
            return {
                   loading : false,
                  search: "",
                    API_NAME: process.env.VUE_APP_API_NAME,
                    accessToken: '',
                    stone_types: [],
                    name: '',
                    stone_type_id: '',
                    description: ''
                   
            }
        },
        created() {
          this.getAccessToken()
            .then(() => { 
               this.getStoneTypes();
            });
          
        },
        methods: {
            async getStoneTypes() {
                UtilityService.getSimpleApiData(this.accessToken, "stone_types").then(
                    response => this.stone_types = response 
                );
                
            },

            async saveStoneMake() {
			
                var formdata = { 
                    name : this.name,
                    stone_type_id : this.stone_type_id,
                    description : this.description
                }; 

                StoneService.saveStoneMake(this.accessToken, formdata)
                .then((response) => {
                    this.$router.push("/stone_makes");
                    console.log(response)
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                        }
                        alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                });
            },
           
        }
    }
    </script>