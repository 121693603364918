<template>
    <div class="home">
        <router-link to="/stone_types" class="is-pulled-right"><button class="button is-info is-small">Back to Stone Types </button></router-link><br /><br />
        <div class="box">	
            <div class="notification is-info" v-if="loading === true">
                <p>Loading stone type ...</p>
            </div>
            
            <div class="notification is-danger" v-if="loading === false && Object.keys(stone_type).length == 0">
                <p>Stone type cannot be found</p>
            </div>
            
            <form @submit.prevent="updateStoneType" v-if="loading === false && Object.keys(stone_type).length > 0">
                      <div class="field">
                          <label class="label">Stone Type Name </label>
                          <div class="control">
                              <input class="input" type="text"  autofocus required v-model="stone_type.name">
                             
                          </div>
                      </div>

                      
                      <div class="field">
                          <label class="label">Description </label>
                          <div class="control">
                              <textarea class="textarea" v-model="stone_type.description"  ></textarea>

                             
                          </div>
                      </div>
  
                     
                      <div class="field is-grouped">
                          <div class="control">
                              <button class="button is-success" :disabled="working===true">Update Stone Type</button>
                          </div>
                      </div>
                  </form>
            
          </div>
          
      </div>
  </template>

<script>
    import StoneService from '@/services/StoneService.js';
    //import UtilityService from '@/services/UtilityService.js';
    
    export default {
      name: 'ViewStoneType',
        mixins: [],
        data: function () {
            return {
                   loading : true,
                   working: false,
                  search: "",
                    API_NAME: process.env.VUE_APP_API_NAME,
                    accessToken: '',
                    stone_type: [],
                    name: '',
                    colour_id: '',
                    description: ''
                   
            }
        },
        created() {
          this.getAccessToken()
            .then(() => { 
               this.getStoneType();
            });
          
        },
        methods: {
            async getStoneType() {
                this.loading = true;
                StoneService.getStoneType(this.accessToken, this.$route.params.id)
                .then(
                    (stone_type => {
                         this.$set(this, "stone_type", stone_type);

                         //console.log(Object.keys(stone_type).length)
                        
                        this.loading=false
                    }).bind(this)
                );
            
        },

            async updateStoneType() {
			
                var formdata = { 
                    _method: 'patch',
                    name : this.stone_type.name,
                    description : this.stone_type.description
                }; 

                StoneService.updateStoneType(this.accessToken, formdata, this.$route.params.id)
                .then((response) => {
                    this.$router.push("/stone_types");
                    console.log(response)
                    
                    }).catch((error) => {
                        
                        if(error.response.status === 409)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data.error));
                            alert("Something went wrong.  \n\nError : " + error.response.data.error)
                        }
                        else
                        {
                            alert("Something went wrong. Please try again or contact David.")
                        }
                });
            },
           
        }
    }
    </script>