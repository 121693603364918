import axios from "axios"

let API_NAME = process.env.VUE_APP_API_NAME;

export default {
  
  async getSimpleApiData(accessToken, endpoint) {
    let res = await axios.get(API_NAME + "/" + endpoint, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    
    return res.data;
  },
  async deleteSimpleApiData(accessToken, endpoint) {
    let res = await axios.delete(API_NAME + "/" + endpoint, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
    return res.data;
  },

  
  
}